<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Edit STK" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Proyek</label>
            <vs-select class="w-full" v-model="data.id_proyek" @input="onChangeProyek" disabled>
              <vs-select-item v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-3/12 w-full.5">
            <label class="ml-1 text-xs">No. SPP *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih SPP" :value="data.no_spk" readonly disabled/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" disabled/>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tgl. STK</label>
            <flat-pickr class="w-full" v-model="data.tgl_stk" disabled></flat-pickr>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">STK Ke / BAST Ke</label>
            <vs-input class="w-full" :value="data.stk_ke" disabled/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tgl. Awal Pelaksanaan SPP</label>
            <vs-input class="w-full" :value="data.awal_pelaksanaan" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tgl. Akhir Pelaksanaan SPP</label>
            <vs-input class="w-full" :value="data.akhir_pelaksanaan" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tgl. Akhir Realisasi SPP</label>
            <vs-input class="w-full" :value="data.realisasi_pelaksanaan || '-'" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Progress Real (%)</label>
            <vs-input class="w-full" :value="data.progress_real || '-'" disabled/>
          </div>
        </div>
        <div class="vx-row mb-3" v-if="data.stk_ke < 2">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Masa Pemeliharaan (Hari)</label>
            <vs-input class="w-full" type="number" v-model="data.masa_pemeliharaan"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Tgl. Akhir Pemeliharaan</label>
            <flat-pickr class="w-full" v-model="data.akhir_tgl_pemeliharaan"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Upload Files</label>
            <vs-input class="w-full" type="file" accept=".jpeg,.jpg,.png,.pdf" multiple v-model="data.filesTemp" @change="onSelectedFiles"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Upload Gambar * <span class="text-xs" v-if="isCompressingGambar">(compressing...)</span></label>
            <vs-input class="w-full" type="file" accept="image/*" multiple v-model="data.gambarTemp" @change="onSelectedPhotos"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.keterangan" />
          </div>
        </div>

        <template v-if="data.stk_ke > 1">
          <vs-divider class="mt-base"> History STK / BAST </vs-divider>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-12/12 w-full">
              <div v-if="stkHistories.length < 1" class="flex justify-center">
                <vs-button type="flat" icon-pack="feather" icon="icon-download-cloud" @click="getHistoryStk" :disabled="isLoadingGetHistoryStk">
                  {{ isLoadingGetHistoryStk ? 'Loading...' : 'Load History STK' }}
                </vs-button>
              </div>
              <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoadingGetHistoryStk }"/>
              <vs-table :data="stkHistories" stripe class="fix-table-input-background">
                <template slot="thead">
                  <vs-th class="whitespace-no-wrap">STK Ke</vs-th>
                  <vs-th class="whitespace-no-wrap">No. STK</vs-th>
                  <vs-th class="whitespace-no-wrap">Tgl. STK</vs-th>
                  <vs-th class="whitespace-no-wrap">Awal Pelaksanaan</vs-th>
                  <vs-th class="whitespace-no-wrap">Akhir Pelaksanaan</vs-th>
                  <vs-th class="whitespace-no-wrap">Masa Pemeliharaan</vs-th>
                  <vs-th class="whitespace-no-wrap">Tgl Akhir Pemeliharaan</vs-th>
                  <vs-th class="whitespace-no-wrap">Progress Real</vs-th>
                  <vs-th class="whitespace-no-wrap">Status Approval</vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr class="text-sm" v-for="(item) in data" :key="item.uuid">
                    <vs-td class="whitespace-no-wrap">{{ item.stk_ke }}</vs-td>
                    <vs-td class="whitespace-no-wrap">{{ item.no_stk }}</vs-td>
                    <vs-td class="whitespace-no-wrap">{{ item.tgl_stk }}</vs-td>
                    <vs-td class="whitespace-no-wrap">{{ item.awal_pelaksanaan }}</vs-td>
                    <vs-td class="whitespace-no-wrap">{{ item.akhir_pelaksanaan }}</vs-td>
                    <vs-td class="whitespace-no-wrap">{{ item.masa_pemeliharaan }} Hari</vs-td>
                    <vs-td class="whitespace-no-wrap">{{ item.akhir_tgl_pemeliharaan }}</vs-td>
                    <vs-td class="whitespace-no-wrap">{{ item.progress_real ? `${item.progress_real}%` : '-' }}</vs-td>
                    <vs-td class="whitespace-no-wrap">{{ item.status_approval }}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </template>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modal spk-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data SPP"
                :active.sync="modalSpk.active">
        <Spk :selectable="true"
             :externalFilter="filterSpk"
             @selected="onSelectedModalSpk"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import StkRepository from '@/repositories/proyek/stk-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import Spk from '@/views/pages/proyek/spk/Spk'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import { compressImage, convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'StkEdit',
  components: {
    ValidationErrors,
    flatPickr,
    Spk
  },
  props: ['isActive', 'item'],
  watch: {
    isActive (active) {
      if (active) {
        this.data = _.cloneDeep(this.item)
      }

      this.data.gambar = null
      this.data.file = null
    }
  },
  mounted () {
    this.getAllProyek()
  },
  computed: {
    filterSpk () {
      return {
        id_proyek: this.data.id_proyek,
        status_approval: 'DITERIMA',
        stk_eligible: true
      }
    }
  },
  data () {
    return {
      isCompressingGambar: false,
      isLoadingGetHistoryStk: false,
      isLoading: false,
      errors: null,
      modalSpk: {
        active: false
      },
      data: {
        id_proyek: null
      },
      gambar: [],
      files: [],
      stkHistories: [],
      proyeks: []
    }
  },
  methods: {
    async onSelectedPhotos (event) {
      const files = event.target.files
      const items = []
      this.isCompressingGambar = true
      for (const file of files) {
        const compressed = await compressImage(file)
        items.push(compressed)
      }
      this.isCompressingGambar = false
      this.data.gambar = items
    },

    onSelectedFiles (event) {
      const files = event.target.files
      const items = []
      for (const file of files) {
        items.push(file)
      }
      this.data.files = items
    },

    getHistoryStk () {
      this.isLoadingGetHistoryStk = true

      const params = {
        id_proyek: this.data.id_proyek,
        id_spk: this.data.id_spk
      }
      StkRepository.getHistory(params)
        .then(response => {
          this.stkHistories = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoadingGetHistoryStk = false
        })
    },

    onChangeProyek () {
      this.data.no_spk = null
      this.data.id_spk = null
    },

    onSelectedModalSpk (item) {
      this.data.id_spk = item.id
      this.data.no_spk = item.no_spk
      this.data.awal_pelaksanaan = item.tgl_awal
      this.data.akhir_pelaksanaan = item.tgl_akhir
      this.data.stk_ke = item.last_stk + 1
      this.data.progress_real = item.progress_terakhir
      this.data.realisasi_pelaksanaan = item.tgl_progress_terakhir
      this.modalSpk.active = false

      if (this.data.stk_ke > 1) {
        this.stkHistories = []
        this.getHistoryStk()
      }
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData({
        id: this.data.id,
        id_proyek: this.data.id_proyek,
        id_spk: this.data.id_spk,
        stk_ke: this.data.stk_ke,
        tgl_stk: this.data.tgl_stk,
        awal_pelaksanaan: this.data.awal_pelaksanaan,
        akhir_pelaksanaan: this.data.akhir_pelaksanaan,
        masa_pemeliharaan: this.data.masa_pemeliharaan,
        akhir_tgl_pemeliharaan: this.data.akhir_tgl_pemeliharaan,
        progress_real: this.data.progress_real,
        keterangan: this.data.keterangan,
        gambar: this.data.gambar,
        dokumen: this.data.files
      })
      StkRepository.update(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
